<template>
    <v-container >
        <v-row justify="center" class="login">
            <v-col sm="12" md="6">
                <h2>{{$t('forgotPassword.welcomeMsg')}}</h2>
                <h4>{{$t('forgotPassword.willSentToEmail')}}</h4>
                <AppForgotPasswordForm></AppForgotPasswordForm>
            </v-col>
            <!-- <v-col v-if='windowWidth > 960'  sm="0" md="6" >
                <div class="login-bg"></div>
            </v-col> -->

        </v-row>
        
    </v-container>
</template>

<script>
// import i18n from '@/plugins/i18n';


// COMPONENTS
import AppForgotPasswordForm from '@/components/Form/AppForgotPasswordForm';


export default {
    components: {
        AppForgotPasswordForm
    },
     data() {
        return {
            windowWidth: window.innerWidth
        }
    },
    mounted() {
        window.onresize = () => {
            this.windowWidth = window.innerWidth
        }
    }
}
</script>
<style lang="scss">
.login {
    flex-direction: column;
    align-items: center;
}
</style>
