<template>
    <v-form @submit.prevent="loginHandlerSubmit" ref="form" v-if="!forgetPasswordSuccess">
        <v-text-field
            v-model="forgotPassword.email"
            :label="$t('signup.emailPlaceholder')"
            :loading="loadingForm"
            :color="colors.brandPrimColor"
            light
            height="40"
            outlined
            append-icon="mdi-email-outline"
            :rules="emailRules"
            
        ></v-text-field>


        <!-------------------------  FORM ERRORS  ------------------->
        <div v-if="errors" class="subtitle1 text-center text-capitalize red--text">
            <p v-for="(errorMessage, i) in errors" :key="i">{{errorMessage}}</p>
        </div>
        <!-------------------------  END FORM ERRORS ------------------->

        <div class="mt-5">
            <v-btn type="submit" large :loading="loadingForm"   :color="colors.brandPrimColor" elevation="0" dark>
                {{$t('forgotPassword.resetTitle')}}
            </v-btn>
            <!-- <router-link class="v-btn v-btn--depressed theme--light v-size--large mr-4" to="/login">{{$t('login.signinBtn')}}</router-link>             -->

        </div>
    </v-form>
    <div v-else>
        <v-alert type="success">
            {{$t('forgotPassword.successMsg')}}
        </v-alert>
        <div class="mail-box">
            <a target="_blank" href="https://mail.google.com/mail/u/0/#inbox">
            <i class="fas fa-envelope-open-text"></i>
            <div>{{$t('forgotPassword.goToMail')}}</div>
            </a>
        </div>
    </div>
</template>

<script>
// MIXINS
import { reset } from '@/mixins/mixins';
import i18n from '@/plugins/i18n';


// SVG ICONS
import { mdiEyeOutline, mdiEyeOffOutline  } from '@mdi/js';

//VUEX
import { mapGetters } from 'vuex';
import axios from 'axios';


export default {
    mixins: [reset],
    data() {
        return {
            forgetPasswordSuccess: false,
            forgotPassword: {
                email: '',
            },
            emailRules: [
                v => !!v || i18n.messages[this.locale].errorsList.signup.errors.reqEmail,
                v => /.+@.+\..+/.test(v) || i18n.messages[this.locale].errorsList.signup.errors.emailMustValid,
            ],
           
        }
    },
    methods: {
        loginHandlerSubmit() {
            if (this.$refs.form.validate()) {
                 this.$store.dispatch('FORGOT_PASSWORD', {
                    email: this.forgotPassword.email,
                    locale: this.locale,
                })
                .then(() => {
                    
                    //SHOW SUCCESS MSG
                    this.forgetPasswordSuccess = !this.forgetPasswordSuccess;
                    // this.$router.push({name: 'forgetPasswordSuccess'});
                })
            }
        },
        
    },
    computed: {
        ...mapGetters(['colors', 'errors', 'loadingForm', 'theme', 'locale', 'getErrorsList'])
    },
    beforeDestroy() {
        this.$store.dispatch('CLEAR_ERROR')
    },
    mounted() {
        

        

    },
}
</script>
<style lang="scss" scoped>

    fieldset {
        display: none;
    }

    .mail-box {
        text-align: center;
       i {
           display: inline-block;
           font-size: 50px;
           margin: 20px;
           color:  var(--brandPrimColor)
       }
    }
</style>
